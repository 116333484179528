import React from "react"
import { Link } from "@StarberryUtils"
import Results from "../../../templates/search-results-map-template";
import "../../property/assets/styles/_index.scss"
import { parseSearchUrl, propertyH1, savedSearchParams } from "../../../Components/SearchResult/utils-map";

const ResidentialLettingsMap = (props) => {

    const fullpath = props.location.pathname
    const fullpathone = props.location
    const searchParams = parseSearchUrl("lettings", fullpath); 

    return (
        <>
            <Results 
                locationname="/property-map/to-rent/" 
                pcategorytype="residential" 
                fullpathone={fullpathone} 
                fullpathname={fullpath} 
                ptype="lettings" 
                ptypetag="to-rent" 
                pstatustype="To Let"
            />
        </>
    )
}

export default ResidentialLettingsMap